<template>
  <div>
    <div class="header">
      <div class="header-left">
        <div class="logo">
          <a href="" @click="goHomePage"><img src="./../../../public/assets/digi/images/sohoj-white-logo.svg"
              alt=""></a>
        </div>
      </div>
      <div class="header-right">
        <audio style="display: none;" controls ref="player" :src="audioFile">
          <source :src="audioFile" type="audio/mpeg" />
        </audio>

        <!-- <div class="speaker-box" @click.prevent="toggleAudio()">
          <a href="javascript:void(0)" v-if="play" id="loud" class="loud mute" data-text="Stop"></a>
          <a href="javascript:void(0)" v-else id="inner_loud" class="loud"></a>
        </div> -->
      </div>
    </div>
    <div class="home-top-section m-2" id="home-top-section">
      <div id="banner_hide_show" style="display: block">
        <div class="banner">
          <slick ref="slick2" v-if="getCampaignServices.length > 0" :options="slickOptions">
            <div class="campaign-image" v-for="campaignservice in getCampaignServices">
              <a :href=campaignservice.link class="banner-click">
                <img :src="pictureBaseUrl + '/' + campaignservice.image" alt="campaign">
              </a>
            </div>
          </slick>
          <div class="d-flex justify-content-center align-items-center vh-100"
            v-else-if="getCampaignServices.length == 0">
            <img :src="pictureBaseUrl2+'/images/campaign/campaign.png'" alt="campaign">
          </div>
        </div>

        <div class="campaign-operators">
          <h3>Select Your Operator</h3>
          <div class="row campaign-box">

            <div class="col-md-6 campaign-item">
              <a href="" @click="submit('dg')">
                <img :src="pictureBaseUrl2 + '/images/campaign/digi.svg'" alt="digi">
                Digi
              </a>
            </div>

            <!-- <div class="col-md-6 campaign-item">
              <a href="" @click="submit('um')">
                <img :src="pictureBaseUrl2 + '/images/campaign/umobile.svg'" alt="U mobile">
                U Mobile
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import Slick from 'vue-slick'
// import selectLanguage from '@/views/includes/select-language.vue'

export default {
  name: 'campaign',
  components: {
    /* selectLanguage, */
    Slick
  },
  data () {
    return {
      slickOptions: {
        // options can be used from the plugin documentation
        dots: true,
        arrows: false,
        // loop: true,
        swipe: true,
        infinite: true,
        pauseOnHover: false,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'ondemand'
      },
      apiUrl: 'content/notice-page',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_ASSET_UPLOAD_URL,
      pictureBaseUrl2: process.env.VUE_APP_MAIN_URL,
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn') ?? '',
      showModal: false,
      operatorId: localStorage.getItem('operatorId') ?? '1',
      index_no: '',
      detailsShow: false,

      // audioFile: 'https://vivr.dotlines.com.sg/uploads/files/ad7f31be63617b0e3a8446753815f887.mp3',
      audioFile: '',
      isPlaying: false,
      audioPlayer: null,
      play: true,

      getCampaignServices: {},
      operator1_Services: {},
      operator2_Services: {},
      paidServiceUrl: 'get-campaign'
    }
  },
  mounted: function () {
    localStorage.setItem('shortcut_modal', 'false'),
      this.initialize()
    new Swiper('.swiper', {
      init: true,
      loop: true,
      speed: 800,
      slidesPerView: 1,
      centeredSlides: true,
      effect: 'coverflow',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true
      },
      grabCursor: true,
      parallax: true,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false
      }
    })
  },
  methods: {
    //...mapActions(['GET_LANGUAGE']),
    next() {
      this.$refs.slick.reSlick()
      this.$refs.slick2.reSlick()
    },
    prev() {
      this.$refs.slick.prev()
      this.$refs.slick2.prev()
    },
    async initialize() {
      console.log('App component mounted.')
      this.audioPlayer = this.$refs.player
      if (this.audioPlayer.paused) {
        console.log('Playing.')
        this.audioPlayer.play()
        this.isPlaying = true
      } else {
        this.audioPlayer.pause()
        this.isPlaying = false
      }

      await this.getCampaign().then(response => {
        this.getCampaignServices = response.data.campaigns;
        //console.log(response.data.campaigns);
      }),
        this.swiperOptions()
    },
    getCampaign() {
      return new Promise((resolve, reject) => {
        axios
          .get(this.paidServiceUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },

    goHomePage() {
      this.$router.push({ name: 'FrontHome' })
    },
    toggleAudio() {
      this.play = !this.play
      var audioPlayer = this.$refs.player
      // var audio = document.getElementById("audio-player");
      if (audioPlayer.paused) {
        audioPlayer.play()
        this.isPlaying = true
      } else {
        audioPlayer.pause()
        this.isPlaying = false
      }
    },

    swiperOptions() {
      $(document).ready(function () {
        var swiper = new Swiper('.swiper-container', {
          init: true,
          loop: true,
          speed: 800,
          slidesPerView: 2,
          centeredSlides: true,
          effect: 'coverflow',
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
          },
          grabCursor: true,
          parallax: true,
          autoplay: {
            delay: 1000,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination'
          }
        })
      })
    },

    submit(op) {
      if (op === 'dg') {
        this.$router.push('/topup-services/topup?op=1&lang=23')
      } else if (op === 'um') {
        this.$router.push('/topup-services/topup?op=2&lang=12')
      }
    }
  },
  watch: {
    detectAudioPlaying: function (newValue) {
      if (newValue) {
        this.audioPlayer.play()
        this.isPlaying = true
      } else {
        this.audioPlayer.pause()
        this.isPlaying = false
      }
    }
  },
  beforeMount() {
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy()
    }
    if (this.$refs.slick2) {
      this.$refs.slick2.destroy()
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions)
      }
      if (this.$refs.slick2) {
        this.$refs.slick2.create(this.slickOptions)
      }
    })
  }
}
</script>

<style scoped></style>
