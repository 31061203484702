<template>
  <body id="site-body" class="new-body">
  <div class="home-top-section" id="home-top-section">
    <div id ='loader' v-if="isLoading"></div>
    <!-- <div class="operator-modal" ref="modal" id="operatorModal" v-if="isLoading">
      <div class="operator-modal-inner">
        <div class="operator-modal-content">
          <div class="spinner-container">
            <div id ='loader'></div>
          </div>
        </div>
      </div>
    </div> -->
    <div v-else>
   
    <div id="banner_hide_show" style="display: block">
      <div class="banner">

        <slick ref="slick" :options="slickOptions">
          <div v-for="val in banner">
            <a href="javascript:void(0);" @click="goBannerDetails(val.link,val.target_page,val.type)"
               class="banner-click" data-href=""
               data-target_page="quizgiri">
              <img :src="val.image" alt="">
            </a>
          </div>
        </slick>
      </div>
    </div>
  </div>
  <div class="free-services" style="display: block"  >
    <h3 v-if="freeServiceList.free_services.length > 0">{{ !_.isEmpty(freeServiceList) ? freeServiceList.free_service : '' }}</h3>
    <!-- Swiper -->

    <div class="swiper-container">

      <div class="swiper-wrapper">

        <div class="swiper-slide" v-for="free in freeServiceList.free_services">

          <a href="javascript:void(0);"
             data-group_id="27" data-href="https://adda.sohoj.my/"
             data-target_page="external_url"
             data-click_event="twocups" @click="submitFreeService(free.link,free.target_page_name,free.type,free.group_id)"
             class="swiper-slide">
            <img :src="!_.isEmpty(free) ? free.image_link : ''" class="entity-img" alt=""/>
          </a>
        </div>
      </div>
      <!-- Add Pagination -->

      <div class="swiper-pagination"></div>

    </div>

    <!-- Swiper End -->
  </div>

  <div id="page-container">
    <div class="container">
      <h3 class="other-service" v-if="showTitle">{{ !_.isEmpty(paidservicelist) ? paidservicelist.other_service : '' }}</h3>
      <div class="categories-list" v-for="paidservice in paidservicelist.service">
        <a href
           @click.prevent="getPaidServiceUrl(paidservice.external_url,paidservice.target_page,paidservice.type,paidservice.id)"
           data-group_id="106"
           data-href="javascript:void(0);"
           class="refer-section">
          <img :src="paidservice.image" alt="">
        </a>
      </div>
      <a v-if="showTitle" class="terms-link-box" @click="termsPage" href="javascript:void (0);" id="terms_of_use">
        {{ !_.isEmpty(paidservicelist) ? paidservicelist.terms_of_use_text : '' }}
      </a>
    </div>
  </div>
  </div>

  <div class="custom_share" id="custom_share"></div>

  </body>
</template>

<script>

import $ from 'jquery'
import Slick from 'vue-slick'
import axios from 'axios'
import selectLanguage from '@/views/includes/select-language'
import { mapActions } from 'vuex'
import { uuid } from 'vue-uuid'
import { saveUserJourney } from './../services/api/saveUserJourney'
import { common_function } from './../../public/front/js/inner_script'
import {updateEvent} from "@/services/api/vivr-engine-helper";

export default {
  name: 'FrontHome',
  components: {
    Slick,
    selectLanguage
  },
  data () {
    return {
      slickOptions: {
        // options can be used from the plugin documentation
        dots: true,
        arrows: false,
        // loop: true,
        swipe: true,
        infinite: true,
        pauseOnHover: false,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'ondemand'
      },
      showTitle: false,
      isLoading: true,
      false : true,
      isActive: false,
      audioFile: require('../../public/assets/amar_song.mp3'),
      BannerApiUrl: '/get-banner',
      operatorApiUrl: '/get-operator',
      operatorRedirectUrl: '/operator/',
      paidServiceUrl: '/paid-service',
      freeServiceUrl: '/free-service',
      menuApiUrl: '/get-menu',
      operatorId: (localStorage.getItem('operatorId') ?? '1'),
      banner: {},
      //languageId: 1,
      languageId: 23,
      operatorList: {},
      paidservicelist: {},
      menuItem: {},
      freeServiceList: {
        free_services: []
      },
      play: true,
      balanceApiUrl: 'get_user_balance_info/',
      languageIdApiUrl: '/get-language-id',
      balanceInfo: {},
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn') ?? '',
      opertorModal: (sessionStorage.getItem('show_popup') ?? '') == '' || sessionStorage.getItem('show_popup') === 'true'
    }
  },

  methods: {
    ...mapActions(['GET_LANGUAGE']),
    next () {
      this.$refs.slick.reSlick()
    },
    prev () {
      this.$refs.slick.prev()
    },
    async Init (operatorId) {
      if (this.$route.params.op !== null && this.$route.params.op !== undefined && this.$route.params.op !== '')
      {
        operatorId = this.$route.params.op
      }

      this.languageId = sessionStorage.getItem('language') ?? 1

      await this.getBanner(operatorId).then(response => {
        this.banner = response.data
      })
      this.isLoading = false;
      
      await this.getPaidService(operatorId).then(response => {
        this.paidservicelist = response.data
      })
      
      await this.getFreeService(operatorId).then(response => {
        this.freeServiceList = response.data
      })
      this.swiperOptions()
      const pageID = this.paidservicelist.prompt.pageID
      const eventID = this.paidservicelist.prompt.eventID
      updateEvent(pageID, eventID)
      
      if (this.operatorId) {
        await this.saveUserHistory(this.operatorId, this.msisdn)
        saveUserJourney(this.operatorId, 'home_page', this.msisdn, this.languageId, this.sessionId)
      }
      this.showTitle = true;
      
    },
    termsPage () {
      this.$router.push({ name: 'termsAndCondition' })
    },
    async toggleOperatorChoose (operatorId) {
      if (operatorId !== localStorage.getItem('operatorId')) {
        if (localStorage.getItem('msisdn') !== '') {
          localStorage.removeItem('msisdn')
          this.msisdn = ''
        }
      }
      await this.getLanguageIdFromDB(operatorId).then(response => {
        sessionStorage.setItem('language', response.data)
        this.languageId = response.data
      })

      if (!this.operatorId) {
        this.saveUserHistory(this.operatorId, this.msisdn)
        saveUserJourney(this.operatorId, 'home_page', this.msisdn, this.languageId, this.sessionId)
      }
      // if localstorage has the operator
      // then use the operator to call the language
      // first set the operator and then call the language
      sessionStorage.setItem('show_popup', 'false')
      this.opertorModal = !this.opertorModal
      this.GET_LANGUAGE(operatorId)
      localStorage.setItem('operatorId', operatorId)
      this.Init(operatorId)
    },
    getBanner (operatorId) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.BannerApiUrl, null, { params: { operatorId: operatorId, languageId: this.languageId } })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    playAudio () {
      // Access the audio element and call the play() method
      this.$refs.audioPlayer.play()
    },
    getOperatorList () {
      return new Promise((resolve, reject) => {
        axios
          .get(this.operatorApiUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getPaidService (operatorId) {
      // console.log('paid-service', this.languageId)

      return new Promise((resolve, reject) => {
        axios
          .post(this.paidServiceUrl, { operatorId: operatorId, languageId: this.languageId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },

    getFreeService (operatorId) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.freeServiceUrl, { operatorId: operatorId, languageId: this.languageId, msisdn: this.msisdn })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    // setLanguageIdInSession (operatorId) {
    //   this.getLanguageIdFromDB(operatorId).then(response => {
    //     sessionStorage.setItem('language', response.data)
    //     this.languageId = response.data
    //     console.log('this.languageId sudipto:', this.languageId)
    //     console.log('session language api', response.data)
    //     console.log('session language api from session', sessionStorage.getItem('language'))
    //   })
    // },
    getLanguageIdFromDB (operatorId) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.languageIdApiUrl, { operatorId: operatorId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    goBannerDetails (serviceUrl, targetPage, type, id) {
      sessionStorage.setItem('serviceGroupId', id)
      sessionStorage.setItem('serviceUrl', serviceUrl)
      sessionStorage.setItem('service', targetPage)
      sessionStorage.setItem('serviceType', type)
      if (type === 'external-link') {
        location.href = serviceUrl
      } else if (type === 'internal-link') {
        this.$router.push({ path: serviceUrl })
      }
    },
    getPaidServiceUrl (serviceUrl, targetPage, type, id) {
      sessionStorage.setItem('serviceGroupId', id)
      sessionStorage.setItem('serviceUrl', serviceUrl)
      sessionStorage.setItem('service', targetPage)
      sessionStorage.setItem('serviceType', type)

      if (type === 'mutiple-service') {
        this.$router.push({ name: 'ListService', params: { service: targetPage } })
      } else if (type === 'topup') {
        this.$router.push({ name: 'topUp', params: { service: targetPage } })
      } else if (type === 'internet') {
        this.$router.push({ name: 'InternetService', params: { service: targetPage } })
      } else if (type === 'input') {
        this.$router.push({ name: 'input-msisdn-page', params: { service: targetPage } })
      } else if (type === 'package-service') {
        this.$router.push({ name: 'packageService', params: { service: targetPage } })
      } else if (type === 'refer' || type === 'transaction' || type === 'subscription') {
        if (this.msisdn === '') {
          this.$router.push({ name: 'input-msisdn-page', params: { service: targetPage } })
        } else {
          if (type === 'refer') {
            this.$router.push({ name: 'refer-friend', params: { service: targetPage } })
          } else if (type === 'transaction') {
            this.$router.push({ name: 'userHistory', params: { service: targetPage } })
          } else if (type === 'subscription') {
            this.$router.push({ name: 'mySubscription', params: { service: targetPage } })
          }
        }
      } else if (type === 'operator') {
        this.opertorModal = !this.opertorModal
        this.isActive = !this.isActive
      } else if (type == 'games') {
        this.$router.push({ name: 'listServiceDetails', params: { service: targetPage } })
      } else {
        this.$router.push({ name: 'servicePage', params: { service: targetPage } })
      }
    },
    saveUserHistory (operatorId, msisdn) {
      axios
        .post('save_user_histories', { operatorId: this.operatorId, msisdn: this.msisdn })
    },
    async OperatorList () {
      await this.getOperatorList().then(response => {
        this.operatorList = response.data
      })
    },
    swiperOptions () {
      $(document).ready(function () {
        var swiper = new Swiper('.swiper-container', {

          init: true,

          loop: true,

          speed: 800,

          slidesPerView: 2,

          centeredSlides: true,

          effect: 'coverflow',

          coverflowEffect: {

            rotate: 50,

            stretch: 0,

            depth: 100,

            modifier: 1,

            slideShadows: true

          },

          grabCursor: true,

          parallax: true,

          autoplay: {

            delay: 1000,

            disableOnInteraction: false

          },

          pagination: {

            el: '.swiper-pagination'

          }

        })
      })
    },
    submitFreeService (link, targetPage, type, id) {
      // console.log('target_page' + targetPage)
      // console.log('type' + type)
      // console.log('link' + link)
      if (targetPage === 'external-url' && link !== '') {
        // window.open(link)  //if want to open link in new tab
        {
          location.href = link
        }
      } else if (targetPage === 'free-service-games') {
        // console.log('clicked' + id)
        this.$router.push({ name: 'freeService', params: { id: id } })
      } else if (targetPage === 'notification') {
        this.$router.push({ name: 'notice-page', params: { id: id } })
      }
    }
  },
  mounted () {
   
    if (!this.sessionId) {
      this.sessionId = uuid.v4()
      sessionStorage.setItem('sessionId', this.sessionId)
    }

    this.Init(this.operatorId);
    // setTimeout(() => {this.isLoading = false;}, 1000);

    new Swiper('.swiper', {
      init: true,
      loop: true,
      speed: 800,
      slidesPerView: 2,
      centeredSlides: true,
      effect: 'coverflow',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true
      },
      grabCursor: true,
      parallax: true,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false
      }
    })
  },
  beforeMount () {
  },
  beforeUpdate () {
    if (this.$refs.slick) {
      this.$refs.slick.destroy()
    }
  },
  updated () {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions)
      }
    })
  }
}

</script>

<style scoped>

</style>
