<template>
  <div class="header">
    <div class="header-left">
      <button class="navbar-toggler" v-if="routeName==='FrontHome' || routeName === 'FrontHomeOp'"
              style="display: block" v-on:click="toggoleCollapse" :class="{open : isActive}">
        <span class="navbar-icon"></span>
        <span class="navbar-icon"></span>
        <span class="navbar-icon"></span>
      </button>
      <a href="" id="top_left_icon" class="back-button" v-else @click.prevent="goBack"><img
        src="./../../../public/assets/digi/images/back-white-btn.svg" alt=""></a>
      <div class="logo">
        <a href="" @click="goHomePage"><img src="./../../../public/assets/digi/images/sohoj-white-logo.svg" alt=""></a>
      </div>
      <div class="navbar-collapse" :class="{show : isActive}">
        <div class="sidebar-wrapper">
          <div id="sidebar-top" data-mobile_no_text="মোবাইল নাম্বার" data-refund_point_text=" রিফান্ড পয়েন্ট"
               data-referal_point_text="বোনাস পয়েন্ট" v-show="msisdn">
            <div class="sidebar-top">
              <h4><span>Mobile Number</span> : {{ msisdn }} </h4>
              <div v-if="operatorId==='1'">
                <h4><span> Refund Point</span> : <a class="header-list" data-target_page="history"
                                                    href="javascript: void(0);"> {{ balanceInfo.refund_point }}</a></h4>
                <h4><span>Bonus Point</span> : <a class="header-list" data-target_page="refer"
                                                  href="javascript: void(0);">{{ balanceInfo.total_point }}</a></h4>
              </div>
              <div class="terms-support" v-if="operatorId==='1'">
                <ul>
                  <li>
                    <a href="javascript:void(0)" @click="getBalanceInfo" id="check_balance"
                       style="color: #333; font-weight: normal !important;">
                      <img src="../../../public/assets/digi/images/sync-rotate-reload-refresh-icon.svg" alt=""
                           style="height: 20px!important;"> RECHECK BALANCE
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sidebar-list">
            <ul>
              <li v-for="menu in menuItem">
                <a class="header-list" @click="getPaidServiceUrl(menu.external_url , menu.target_page,menu.type)"
                   data-target_page="refer" href="javascript:void(0);">
                  {{ menu.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header-right">
      <selectLanguage/>
      <!-- <audio id="play_audio_media" controls style="display: none;">
          <source :src="audioFile" type="audio/mpeg">
      </audio> -->
      <audio
        style="display: none;" controls
        ref="player"
        :src="audioFile"
      >
        <source :src="audioFile" type="audio/mpeg"/>
      </audio>
      <!-- <div class="speaker-box" @click.prevent="toggleAudio()">
        <a href="javascript:void(0)" v-if="play" id="loud" class="loud mute" data-text="Stop"></a>
        <a href="javascript:void(0)" v-else id="inner_loud" class="loud"></a>
      </div> -->
    </div>
    <div class="operator-modal" ref="modal" id="operatorModal" v-if="opertorModal">
      <div class="operator-modal-inner">
        <div class="operator-modal-content">
          <h3>Select Your Operator</h3>
          <div v-for="op in operatorList">
            <a href="" @click.prevent="toggleOperatorChoose(op.id)">
              <img :src="op.logo" :alt="op.name"> {{ op.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectLanguage from '@/views/includes/select-language'
import { CommonAPI } from '@/services/api'
import mqtt from 'mqtt'
import axios from 'axios'
import { mapActions } from 'vuex'
import { saveUserJourney } from '@/services/api/saveUserJourney'

export default {
  name: 'clientHeader',
  components: {
    selectLanguage
  },
  data () {
    return {
      previousRoute: sessionStorage.getItem('previous_route'),
      re_token: '',
      re_product_id: '',
      play: true,
      connection: {
        host: '',
        port: '',
        endpoint: '/mqtt',
        clean: true,
        connectTimeout: 4000,
        reconnectPeriod: 4000,
        clientId: '',
        username: '',
        password: ''
      },
      subscription: {
        topic: '',
        qos: 0
      },
      subscribeSuccess: false,
      audioFile: '', // https://vivr.dotlines.com.sg/uploads/files/ad7f31be63617b0e3a8446753815f887.mp3',
      isActive: false,
      msisdn: localStorage.getItem('msisdn') ?? '',
      balanceInfo: {},
      menuItem: {},
      toggleShow: true,
      backBtnShow: false,
      //operatorId: (localStorage.getItem('operatorId') ?? ''),
      operatorId: '1',
      menuApiUrl: '/get-menu',
      balanceApiUrl: 'get_user_balance_info/',
      operatorApiUrl: '/get-operator',
      languageId: sessionStorage.getItem('language') ?? 1,
      opertorModal: false,
      //opertorModal:(sessionStorage.getItem('show_popup') ?? '') == '' || sessionStorage.getItem('show_popup') === 'true',
      operatorList: {},
      languageIdApiUrl: '/get-language-id',
      routeName: 'FrontHome',
      isPlaying: false,
      audioPlayer: null
    }
  },
  watch: {
    '$route.name': function (newRouteName, oldRouteName) {
      this.routeName = newRouteName;
      
    },
    detectAudioPlaying: function (newValue) {
      if (newValue) {
        this.audioPlayer.play()
        this.isPlaying = true
      } else {
        this.audioPlayer.pause()
        this.isPlaying = false
      }
    }
  },
  computed: {
    detectAudioPlaying () {
      return this.$store.state.auth.isAudioPlaying
    }
  },
  methods: {
    ...mapActions(['GET_LANGUAGE']),

    async initialize () {
      if (this.$route.query.op && this.$route.query.lang) {
        this.languageId = this.$route.query.lang
        await this.getOpAndLangFromUrl(this.$route.query.op)
      } else if (this.$route.query.op) {
        await this.toggleOperatorChoose(this.$route.query.op)
      }
      console.log('App component mounted.')
      this.audioPlayer = this.$refs.player
      if (this.audioPlayer.paused) {
        console.log('Playing.')
        this.audioPlayer.play()
        this.isPlaying = true
      } else {
        this.audioPlayer.pause()
        this.isPlaying = false
      }

      this.show_notification = true
      this.connection = {
        host: this.APP_MQTT_SERVER,
        port: this.APP_MQTT_CLIENT_PORT,
        endpoint: '/mqtt',
        clean: true,
        connectTimeout: 4000,
        reconnectPeriod: 4000,
        clientId: '',
        username: this.APP_MQTT_USERNAME,
        password: this.APP_MQTT_PASSWORD
      }
      if (this.$route.name === 'FrontHome') {
        this.toggleShow = true
      } else {
        this.toggleShow = false
      }
      this.createConnection()
      await this.getMenus(this.operatorId).then(response => {
        this.menuItem = response.data
      })
      if (this.msisdn && this.operatorId === '1') {
        await this.getBalanceInfo().then(response => {
          this.balanceInfo = response.data
        })
      }

      this.toggleOperatorChoose('1');
      
    },
    uid () {
      return 'id_' + Date.now() + String(Math.random()).substr(2)
    },

    createConnection () {
      const { host, port, endpoint, ...options } = this.connection
      let protocol = 'ws'
      if (parseInt(this.APP_MQTT_HTTPS_ENABLE) === 1) {
        protocol = 'wss'// `wss://${host}:${port}${endpoint}`
      }

      const connectUrl = `${protocol}://${host}:${port}${endpoint}`
      
      let subscription_id = ''
      if (localStorage.getItem('subscription_id') !== null) {
        subscription_id = localStorage.getItem('subscription_id')
      } else {
        subscription_id = this.uid()
        localStorage.setItem('subscription_id', subscription_id)
      }

      this.connection.clientId = 'ivr-guest-channel-' + subscription_id
      options.clientId = 'Client_' + subscription_id
      this.subscription.topic = 'ivr-guest-channel-' + subscription_id
      
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      this.client.on('connect', () => {
        console.log('Connection succeeded!!')
        this.doSubscribe()
      })
      this.client.on('error', error => {
        console.log('Connection failed', error)
      })
      this.client.on('message', (topic, message) => {
        this.updateAudioFile(JSON.parse(message))
      })
    },
    doSubscribe () {
      const { topic, qos } = this.subscription
      this.client.subscribe(topic, qos, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
      })
    },
    makeid: function (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    sendPreviousRoute () {
      this.$router.push({ name: this.previousRoute })
    },
    updateAudioFile (message) {
      const _this = this
      let updateTimeAudioPause = false
     
      if (this.isPlaying) {
        updateTimeAudioPause = true
        this.audioPlayer.pause()
      }
      this.audioFile = message.audio_file
      setTimeout(function () {
        if (updateTimeAudioPause == true) {
          _this.audioPlayer.play()
        }
      }, 100)

    },
    toggleAudio () {
      this.play = !this.play
      var audioPlayer = this.$refs.player
      // var audio = document.getElementById("audio-player");
      if (audioPlayer.paused) {
        audioPlayer.play()
        this.isPlaying = true
      } else {
        audioPlayer.pause()
        this.isPlaying = false
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    goHomePage () {
      this.$router.push({ name: 'FrontHome' })
    },
    toggoleCollapse () {
      this.isActive = !this.isActive
    },
    getBalanceInfo () {
      return new Promise((resolve, reject) => {
        axios
          .get(this.balanceApiUrl + this.msisdn)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getMenus (operatorId) {
      return new Promise((resolve, reject) => {
        axios
          .get(this.menuApiUrl + '/' + this.operatorId + '/' + this.languageId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    async toggleOperatorChoose (operatorId) {
      
      if (operatorId !== localStorage.getItem('operatorId')) {
        if (localStorage.getItem('msisdn') !== '') {
          localStorage.removeItem('msisdn')
          this.msisdn = ''
        }
        sessionStorage.clear()
      }
  
      await this.getLanguageIdFromDB(operatorId,sessionStorage.getItem('languageName')).then(response => {
        sessionStorage.setItem('language', response.data)
        this.languageId = response.data
      })
      
      console.log("client Header : ",this.languageId);
      // if localstorage has the operator
      // then use the operator to call the language
      // first set the operator and then call the language
      sessionStorage.setItem('show_popup', 'false')
      //this.opertorModal = !this.opertorModal
      this.opertorModal = false;
      this.GET_LANGUAGE(operatorId)
      localStorage.setItem('operatorId', operatorId)
      this.$router.push({ name: 'FrontHomeOp', params: { op: operatorId } })
      //location.reload()
      if (!sessionStorage.getItem('hasReloaded')) {
            setTimeout(() => {
            sessionStorage.setItem('hasReloaded', 'true');
            location.reload(); 
          }, 50);
      }
    },
    async getOpAndLangFromUrl (operatorId) {
      if (operatorId !== localStorage.getItem('operatorId')) {
        if (localStorage.getItem('msisdn') !== '') {
          localStorage.removeItem('msisdn')
          this.msisdn = ''
        }
      }

      await this.getLanguageIdFromDB(operatorId,sessionStorage.getItem('languageName')).then(response => {
        if (this.$route.query.op && this.$route.query.lang) {
          sessionStorage.setItem('language', this.$route.query.lang)
        } else {
          sessionStorage.setItem('language', response.data)
        }

        this.languageId = response.data
      })
      sessionStorage.setItem('show_popup', 'false')
      this.opertorModal = false
      this.GET_LANGUAGE(operatorId)
      localStorage.setItem('operatorId', operatorId)
    },
    async OperatorList () {
      await this.getOperatorList().then(response => {
        this.operatorList = response.data
      })
    },
    getLanguageIdFromDB (operatorId,languageName) {

      let language = languageName;
     
      if(language == null){
        language = "bn";
      }

      return new Promise((resolve, reject) => {
        axios
          .post(this.languageIdApiUrl, { operatorId: operatorId,code: language })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getOperatorList () {
      return new Promise((resolve, reject) => {
        axios
          .get(this.operatorApiUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getPaidServiceUrl (serviceUrl, targetPage, type, id) {
      // console.log(type)
      sessionStorage.setItem('serviceGroupId', id)
      sessionStorage.setItem('serviceUrl', serviceUrl)
      sessionStorage.setItem('service', targetPage)
      sessionStorage.setItem('serviceType', type)
      this.toggoleCollapse()
      if (serviceUrl) {
        this.$router.push({ path: serviceUrl })
      } else if (type === 'mutiple-service') {
        this.$router.push({ name: 'ListService', params: { service: targetPage } })
      } else if (type === 'topup') {
        this.$router.push({ name: 'topUp', params: { service: targetPage } })
      } else if (type === 'internet') {
        this.$router.push({ name: 'InternetService', params: { service: targetPage } })
      } else if (type === 'input') {
        this.$router.push({ name: 'input-msisdn-page', params: { service: targetPage } })
      } else if (type === 'package-service') {
        this.$router.push({ name: 'packageService', params: { service: targetPage } })
      } else if (type === 'refer' || type === 'transaction' || type === 'subscription' || type === 'favourite') {
        if (this.msisdn === '') {
          this.$router.push({ name: 'input-msisdn-page', params: { service: targetPage } })
        } else {
          if (type === 'refer') {
            this.$router.push({ name: 'refer-friend', params: { service: targetPage } })
          } else if (type === 'transaction') {
            this.$router.push({ name: 'userHistory', params: { service: targetPage } })
          } else if (type === 'subscription') {
            this.$router.push({ name: 'mySubscription', params: { service: targetPage } })
          } else if (type === 'favourite') {
            this.$router.push({ name: 'favourite-number-view', params: { service: targetPage } })
          }
        }
      } else if (type === 'operator') {
        this.opertorModal = false
        //this.opertorModal = !this.opertorModal
        this.isActive = !this.isActive
      } else if (type == 'games') {
        this.$router.push({ name: 'listServiceDetails', params: { service: targetPage } })
      } else {
        this.$router.push({ name: 'servicePage', params: { service: targetPage } })
      }
    }

  },
  mounted () {
    if (this.$route.query.op) {
      this.opertorModal = false
      this.operatorId = this.$route.query.op
    }
    console.log("language 01 : ",this.languageId);
    this.routeName = this.$route.name
    this.OperatorList()
    if (this.$route.query.re_token && this.$route.query.re_product_id) {
      this.re_token = this.$route.query.re_token
      this.re_product_id = this.$route.query.re_product_id
      sessionStorage.setItem('re_token', this.re_token)
      sessionStorage.setItem('re_product_id', this.re_product_id)
    }
    this.initialize()
  }
}
</script>

<style scoped>

</style>
