<template>
  <div id="page-container">
    <div class="container">
      <div id ='loader' v-if="is_loading"></div>
      <div class="confirmation-box" v-else>
        <h3 v-html="this.serviceConfirmationData.header_text">  </h3>
        <div class="btn-wrapper justify-between">

          <!-- DIGI -->
          <a v-if="this.operatorId === '1'" class="btn failed no" @click="goBack" href="javascript:void(0)" data-service="quizgiri" data-service_id=""
             data-option_id="" data-language="1">{{this.serviceConfirmationData.no_btn_text}}</a>
          <button v-if="this.operatorId === '1'" @click.prevent="goRegistrationPage()" id="ayes" class="btn yes" data-service="quizgiri" data-service_id="" data-option_id=""
                  data-language="1">{{this.serviceConfirmationData.yes_btn_text}}
          </button>

          <!-- UMOBILE -->
<!--          <a v-if="this.operatorId === '2'" class="btn failed no" @click="goBack" href="javascript:void(0)" data-service="quizgiri" data-service_id=""-->
<!--             data-option_id="" data-language="1">{{this.serviceConfirmationData.no_btn_text}}</a>-->

<!--          <form v-if="this.operatorId === '2'" id="umobile_form" method="POST" v-bind:action="this.serviceConfirmationData.actionUrl">-->
<!--            <input type="hidden" name="countryCode" :value="this.serviceConfirmationData.countryCode">-->
<!--            <input type="hidden" name="siteCode" :value="this.serviceConfirmationData.siteCode">-->
<!--            <input type="hidden" name="productName" :value="this.serviceConfirmationData.productName">-->
<!--            <input type="hidden" name="price" :value="this.serviceConfirmationData.price">-->
<!--            <input type="hidden" name="subFrequency" :value="this.serviceConfirmationData.subFrequency">-->
<!--            <input type="hidden" name="currencyCode" :value="this.serviceConfirmationData.currencyCode">-->
<!--            <input type="hidden" name="callbackUrl" :value="this.serviceConfirmationData.callbackUrl">-->
<!--            <input type="hidden" name="requestId" :value="this.serviceConfirmationData.requestId">-->
<!--            <input type="hidden" name="notes" :value="this.serviceConfirmationData.notes">-->
<!--            <input class="btn btn_new" type="submit" name="mpaySubmit" :value="this.serviceConfirmationData.mpaySubmit">-->
<!--          </form>-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'
import {updateEvent} from "@/services/api/vivr-engine-helper";

export default {
  name: 'service-confirmation-page',
  data () {
    return {
      serviceConfirmationDataUrl: '/content/service-confirmation-page',
      serviceConfirmationData: {},
      service: (sessionStorage.getItem('service') ?? ''),
      subService: sessionStorage.getItem('sub_service'),
      is_loading: true,
      serviceType: sessionStorage.getItem('serviceType'),
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      operatorId: localStorage.getItem('operatorId'),
      msisdn: localStorage.getItem('msisdn')
    }
  },
  methods:
    {
      async init () {
        sessionStorage.setItem('previous_route', 'servicePage')
        if (this.serviceType === 'mutiple-service') {
          this.service = this.subService
        }
        await this.getdata().then(response => {
          this.serviceConfirmationData = response.data
          this.is_loading = false
        })
        const pageID = this.dataDetails.prompt.pageID
        const eventID = this.dataDetails.prompt.eventID
        updateEvent(pageID, eventID)
        saveUserJourney(this.operatorId, this.service + '_service_confirmation', this.msisdn, this.languageId, this.sessionId)
      },
      getdata () {
        return new Promise((resolve, reject) => {
          axios
            .post(this.serviceConfirmationDataUrl, { service: this.service, languageId: this.languageId, operatorId: this.operatorId, msisdn: this.msisdn })
            .then((response) => {
              // console.log(response)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      goRegistrationPage () {
        this.$router.push({ name: 'serviceRegistration' })
      },
      goBack () {
        this.$router.go(-1)
      }
    },
  mounted () {
    this.init()
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  }
}
</script>

<style scoped>

</style>
